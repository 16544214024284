<template>
    <b-modal size="xl"
             centered
             hide-header
             hide-footer
             content-class="rounded-xl border-0 shadow"
             body-class="pb-0"
             v-bind:visible="value"
             v-on:hide="hideModal"
             v-on:cancel="hideModal"
             v-on:close="hideModal">
        <div class="position-sticky header d-flex justify-content-between align-items-center bg-white pb-1 mb-3">
            <h1 class="font-weight-bolder mb-0">
                {{ event ? "Update" : "Create" }} activity
            </h1>
            <button class="btn btn-lg btn-outline-dark border-0"
                    v-on:click="hideModal">
                <font-awesome-icon icon="xmark"></font-awesome-icon>
            </button>
        </div>
        <b-form v-on:submit.prevent="submit"
                v-on:reset="reset">
            <div class="form-row">
                <div class="col-lg-4 event-images-wrapper">
                    <b-form-group label="Event Thumbnails"
                                  label-for="eventImages">
                        <div class="position-relative event-images img-thumbnail rounded-xl shadow-sm"
                             v-on:click="changeImages">
                            <input id="eventImages"
                                   class="d-none"
                                   type="file"
                                   multiple
                                   v-on:change="updateImages">
                            <img class="rounded-xl"
                                 alt="Event images"
                                 v-bind:src="images[0]">
                        </div>
                    </b-form-group>
                    <h6 class="text-center">
                        Click to update 👆
                    </h6>
                    <draggable class="thumbnails d-flex flex-wrap"
                               v-model="images">
                        <div class="thumbnail-wrapper pr-1 pb-1"
                             v-for="image in images"
                             v-bind:key="image">
                            <img class="img-thumbnail rounded-xl shadow-sm h-100 w-100"
                                 alt="Event Thumbnails"
                                 v-bind:src="image">
                        </div>
                    </draggable>
                </div>

                <div class="col-lg-8 form-row">
                    <b-form-group class="col-md-6"
                                  label="Status"
                                  label-for="status">
                        <template v-slot:label>
                            🚦 Status
                        </template>
                        <b-form-select id="status"
                                       class="rounded-pill bg-light"
                                       name="status"
                                       required
                                       v-model="status">
                            <b-form-select-option v-for="status in statuses"
                                                  v-bind:key="status"
                                                  v-bind:value="status">
                                {{ status }}
                            </b-form-select-option>
                        </b-form-select>
                    </b-form-group>

                    <div class="col-12 form-row">
                        <b-form-group class="col-6"
                                      label="Promotion"
                                      label-for="isPromoted">
                            <b-form-checkbox id="isPromoted"
                                             switch
                                             color="success"
                                             v-model="isPromoted">
                                {{ isPromoted ? "🟢 Promotion Active" : "🔴 Promotion Disabled" }}
                            </b-form-checkbox>
                        </b-form-group>
                        <b-form-group class="col-6"
                                      label="'Free Activity' Promotion"
                                      label-for="isFree">
                            <b-form-checkbox id="isFree"
                                             switch
                                             color="success"
                                             v-model="isFree">
                                {{ isFree ? "🆓 Free Activity" : "💰 Paid Activity" }}
                            </b-form-checkbox>
                        </b-form-group>
                    </div>
                    <b-form-group class="col-12"
                                  label-for="title">
                        <template v-slot:label>
                            ✒️ Heading <small class="text-danger">* Required</small>
                        </template>
                        <b-form-input id="title"
                                      class="rounded-pill bg-light"
                                      name="title"
                                      type="text"
                                      required
                                      v-model="title">
                        </b-form-input>
                    </b-form-group>
                    <b-form-group class="col-md-6"
                                  label-for="category">
                        <template v-slot:label>
                            🏷️ Category <small class="text-danger">* Required</small>
                        </template>
                        <b-form-select id="category"
                                       name="category"
                                       class="rounded-pill bg-light"
                                       required
                                       value-field="_id"
                                       text-field="name"
                                       v-bind:options="categories"
                                       v-model="category">
                            <template v-slot:first>
                                <option v-bind:value="null">
                                    Please select an event category.
                                </option>
                            </template>
                        </b-form-select>
                    </b-form-group>

                    <div class="col-md-6 d-flex align-items-end mb-3">
                        <button class="btn btn-link"
                                v-on:click.prevent.stop="isAddCategoryModalOpened = true">
                            ✨ Create a new category
                        </button>
                    </div>

                    <b-form-group class="col-12"
                                  label="Description"
                                  label-for="description">
                        <template v-slot:label>
                            📄 Description <small class="text-danger">* Required</small>
                        </template>
                        <b-form-textarea id="description"
                                         name="description"
                                         class="rounded-xl bg-light"
                                         required
                                         placeholder="Enter description..."
                                         rows="6"
                                         max-rows="10"
                                         v-model="description">
                        </b-form-textarea>
                    </b-form-group>
                    <div class="col-md-6">
                        <b-form-group>
                            <template v-slot:label>
                                🌏 Region <small class="text-danger">* Required</small>
                            </template>
                            <b-form-select class="rounded-pill bg-light"
                                           v-model="region">
                                <option v-for="{ _id, name, code } in regions"
                                        v-bind:key="_id"
                                        v-bind:value="code">
                                    {{ name }} ({{ code }})
                                </option>
                            </b-form-select>
                        </b-form-group>
                    </div>
                    <b-form-group class="col-12"
                                  label="Location"
                                  label-for="location">
                        <template v-slot:label>
                            🏢 Location
                        </template>
                        <b-form-input id="location"
                                      name="location"
                                      class="rounded-pill bg-light"
                                      required
                                      v-model="location">
                        </b-form-input>
                    </b-form-group>
                    <b-form-group class="col-12"
                                  label="💲 Price"
                                  label-for="price">
                        <b-form-input id="price"
                                      name="price"
                                      class="rounded-pill bg-light"
                                      required
                                      v-model="price">
                        </b-form-input>
                    </b-form-group>
                    <div class="form-row col-md-6">
                        <b-form-group class="col-12"
                                      v-bind:label="lastsMoreThanOneDay ? 'Start date' : 'Date'"
                                      label-for="start-date">
                            <b-form-input id="start-date"
                                          class="rounded-pill bg-light"
                                          type="date"
                                          required
                                          v-model="startDate">
                            </b-form-input>
                        </b-form-group>
                        <div class="col-12">
                            <b-form-group>
                                <b-form-checkbox switch
                                                 v-model="lastsMoreThanOneDay"
                                                 v-on:change="endDate = lastsMoreThanOneDay ? endDate : undefined">
                                    {{ lastsMoreThanOneDay ? "Multi-day activity" : "Single-day activity" }}
                                </b-form-checkbox>
                            </b-form-group>
                            <b-collapse v-bind:visible="lastsMoreThanOneDay">
                                <b-form-group label="End date"
                                              label-for="end-date">
                                    <b-form-input id="end-date"
                                                  class="rounded-pill bg-light"
                                                  type="date"
                                                  v-model="endDate">
                                    </b-form-input>
                                </b-form-group>
                            </b-collapse>
                        </div>
                    </div>
                    <div class="form-row col-md-6">
                        <b-form-group class="col-12"
                                      label="Start time"
                                      label-for="start-time">
                            <b-form-input id="start-time"
                                          class="rounded-pill bg-light"
                                          type="time"
                                          required
                                          v-model="startTime">
                            </b-form-input>
                        </b-form-group>

                        <div class="col-12">
                            <b-form-group>
                                <b-form-checkbox switch
                                                 v-model="isAllDayEvent"
                                                 v-on:change="endTime = isAllDayEvent ? null : endTime">
                                    {{ isAllDayEvent ? "All-day activity" : "Partial-day activity" }}
                                </b-form-checkbox>
                            </b-form-group>
                            <b-collapse v-bind:visible="!isAllDayEvent">
                                <b-form-group label="End time"
                                              label-for="end-time">
                                    <b-form-input id="end-time"
                                                  class="rounded-pill bg-light"
                                                  type="time"
                                                  v-model="endTime">
                                    </b-form-input>
                                </b-form-group>
                            </b-collapse>
                        </div>
                    </div>
                    <b-form-group class="col-6"
                                  label="💺 Seats available"
                                  label-for="seats">
                        <b-form-input id="seats"
                                      name="seats"
                                      class="rounded-pill bg-light"
                                      required
                                      type="number"
                                      min="0"
                                      step="1"
                                      v-model="available">
                        </b-form-input>
                    </b-form-group>
                    <b-form-group class="col-12"
                                  label="🔗 Contact URL"
                                  label-for="contactUrl">
                        <b-form-input id="contactUrl"
                                      name="contactUrl"
                                      class="rounded-pill bg-light"
                                      required
                                      type="url"
                                      v-model="contactUrl">
                        </b-form-input>
                    </b-form-group>
                </div>
            </div>

            <div class="position-sticky buttons text-center py-3">
                <button type="submit"
                        class="btn btn-primary rounded-pill px-4 mr-2"
                        v-bind:class="{ 'btn-loading': isLoading }">
                    💾 Save
                </button>
                <button type="reset"
                        class="btn btn-secondary rounded-pill px-4"
                        v-bind:class="{ 'btn-loading': isLoading }">
                    ♻️ Reset
                </button>

            </div>
        </b-form>

        <modal-category type="event"
                        v-model="isAddCategoryModalOpened">
        </modal-category>
    </b-modal>
</template>

<script>
import { apiUrl, apiVersion, } from "@/config";
import format from "date-fns/format";
import parse from "date-fns/parse";
import differenceInDays from "date-fns/differenceInDays";
import {
    BModal, BForm, BFormGroup, BFormInput, BFormSelect, BFormSelectOption, BFormCheckbox, BFormTextarea, BCollapse,
} from "bootstrap-vue";
import draggable from "vuedraggable";
export default {
    name: "ModalActivity",
    components: {
        BModal, BForm, BFormGroup, BFormInput, BFormSelect, BFormSelectOption, BFormCheckbox, BFormTextarea, BCollapse,
        draggable,
        ModalCategory: () => import("../modals/ModalCategory"),
    },
    props: {
        "value": {
            type: Boolean,
            default: false,
        },
        "event": {
            type: Object,
        },
    },
    data () {
        return {
            isLoading: false,
            isAddCategoryModalOpened: false,

            lastsMoreThanOneDay: false,
            isAllDayEvent: false,

            eventId: "",
            status: "pending",
            isFull: false,
            isPromoted: false,
            isFree: false,

            title: "",
            category: undefined,
            description: "",
            cancelled: false,
            startDate: undefined,
            startTime: undefined,
            endDate: undefined,
            endTime: undefined,
            region: "",
            location: "",
            price: "",
            available: undefined,
            full: false,
            contactUrl: "",

            images: [],
            imageFiles: [],
        };
    },
    computed: {
        statuses () {
            return ["pending", "approved", "rejected"];
        },
        categories () {
            return this.$store.getters["category/itemsByTypeEvent"];
        },
        availableCategories () {
            return this.categories.filter(({ _id }) => !this.category.includes(_id));
        },
        selectedCategories () {
            return this.category.map(category => this.categories.find(({ _id }) => _id === category));
        },
        regions () {
            return this.$store.getters["region/itemsActive"];
        },
    },
    methods: {
        hideModal () {
            this.$emit("input", false);
        },
        changeImages () {
            document.getElementById("eventImages").click();
        },
        async updateImages (e) {
            const temp = Array.from(e.target.files || e.dataTransfer.files);
            if (temp.length === 0) {
                return;
            }
            this.imageFiles = temp;
            this.images = await Promise.all(
                this.imageFiles.map(
                    file => {
                        const reader = new FileReader();
                        reader.readAsDataURL(file);
                        return new Promise(
                            (resolve) => {
                                reader.onload = (e) => {
                                    resolve(e.target.result);
                                };
                            }
                        );
                    }
                )
            );
        },

        addAttribute () {
            if (this.attributes[this.newAttributeKey] === undefined) {
                this.attributes[this.newAttributeKey] = this.newAttributeValue;
                this.newAttributeKey = "";
                this.newAttributeValue = "";
            }
        },

        deleteAttribute (key) {
            delete this.attributes[key];
        },

        async submit () {
            if (this.event) {
                return this.edit();
            }
            return this.add();
        },

        async add () {
            let {
                status, isPromoted, isFree, isFull,
                title, category, description,
                cancelled,
                startDate, startTime, endDate, endTime,
                location, region, price, available,
                full,
                contactUrl,
                imageFiles,
            } = this;

            this.isLoading = true;

            startDate = new Date(startDate);
            startTime = parse(startTime, "HH:mm", new Date(startDate.getTime()));

            if (endDate) {
                endDate = new Date(endDate);
            }

            if (endTime) {
                if (endDate) {
                    endTime = parse(endTime, "HH:mm", new Date(endDate.getTime()));
                } else {
                    endTime = parse(endTime, "HH:mm", new Date(startDate.getTime()));
                }
            }

            try {
                await this.$store.dispatch(
                    "event/addEvent",
                    {
                        status, isPromoted, isFree, isFull,
                        title, category, description,
                        cancelled,
                        startDate, startTime,
                        endDate, endTime,
                        location, region, price, available,
                        full,
                        contactUrl,
                        "images": imageFiles,
                    }
                );
                this.$emit("input", false);
                this.reset();
            } catch (e) {
                console.error(e);
            } finally {
                this.isLoading = false;
            }
        },

        async edit () {
            let {
                eventId,
                status, isPromoted, isFree, isFull,
                title, category, description,
                cancelled,
                startDate, startTime,
                endDate, endTime,
                location, region, price, available,
                full,
                contactUrl,
                imageFiles,
            } = this;

            this.isLoading = true;

            console.log(endTime);

            startDate = new Date(startDate);
            startTime = parse(startTime, "HH:mm", new Date(startDate.getTime()));

            if (endDate) {
                endDate = new Date(endDate);
            }

            if (endTime) {
                if (endDate) {
                    endTime = parse(endTime, "HH:mm", new Date(endDate.getTime()));
                } else {
                    endTime = parse(endTime, "HH:mm", new Date(startDate.getTime()));
                }
            }

            try {
                await this.$store.dispatch(
                    "event/editEvent",
                    {
                        "_id": eventId,
                        status, isPromoted, isFree, isFull,
                        title, category, description,
                        cancelled,
                        startDate, startTime,
                        endDate, endTime,
                        location, region, price, available,
                        full,
                        contactUrl,
                        "images": imageFiles,
                    }
                );
                this.$emit("input", false);
            } catch (e) {
                console.error(e);
            } finally {
                this.isLoading = false;
            }
        },

        reset () {
            this.eventId = "";
            this.status = "pending";
            this.isPromoted = false;
            this.isFree = false;
            this.isFull = false;
            this.title = "";
            this.category = undefined;
            this.description = "";
            this.cancelled = false;
            this.startDate = undefined;
            this.startTime = undefined;
            this.endDate = undefined;
            this.endTime = undefined;
            this.region = "";
            this.location = "";
            this.price = "";
            this.available = undefined;
            this.full = false;
            this.contactUrl = "";
            this.images = [];
            this.imageFiles = [];
        },
    },
    mounted () {
        if (this.event) {
            const {
                _id, status, isPromoted, isFree, isFull, title, category, description, cancelled,
                startDate, startTime, endDate, endTime,
                location, region, price, available, full, contactUrl, images
            } = this.event;

            this.eventId = _id;
            this.status = status;
            this.isPromoted = isPromoted;
            this.isFree = isFree;
            this.isFull = isFull;
            this.title = title;
            this.category = category._id;
            this.description = description;
            this.cancelled = cancelled;
            this.contactUrl = contactUrl;

            this.startDate = format(
                new Date(startDate),
                "yyyy-MM-dd"
            );

            this.startTime = format(
                new Date(startTime),
                "HH:mm"
            );

            this.lastsMoreThanOneDay = false;
            if (endDate) {
                this.endDate = format(
                    new Date(endDate),
                    "yyyy-MM-dd"
                );

                this.lastsMoreThanOneDay =
                    Math.abs(
                        differenceInDays(
                            new Date(startDate),
                            new Date(endDate)
                        )
                    ) > 0;
            }

            this.isAllDayEvent = true;
            if (endTime) {
                this.endTime = format(
                    new Date(endTime),
                    "HH:mm"
                );
                this.isAllDayEvent = false;
            }

            this.region = region;
            this.location = location;
            this.price = price;
            this.available = available;
            this.full = full;
            this.images = images ?
                images.map(
                    image => `${ apiUrl }/${ apiVersion }/event/image/${ image }`
                ) :
                [];
        }
    },
    watch: {
        "event" (newVal) {
            if (newVal) {
                const {
                    _id, status, isPromoted, isFree, isFull, title, category, description, cancelled,
                    startDate, startTime, endDate, endTime,
                    location, region, price, available, full, contactUrl, images
                } = newVal;
                this.eventId = _id;
                this.status = status;
                this.isPromoted = isPromoted;
                this.isFree = isFree;
                this.isFull = isFull;
                this.title = title;
                this.category = category._id;
                this.description = description;
                this.cancelled = cancelled;
                this.contactUrl = contactUrl;

                this.startDate = format(
                    new Date(startDate),
                    "yyyy-MM-dd"
                );

                this.startTime = format(
                    new Date(startTime),
                    "HH:mm"
                );

                this.lastsMoreThanOneDay = false;
                if (endDate) {
                    this.endDate = format(
                        new Date(endDate),
                        "yyyy-MM-dd"
                    );
                    this.lastsMoreThanOneDay = Math.abs(
                        differenceInDays(
                            new Date(startDate),
                            new Date(endDate)
                        )
                    ) > 0;
                }

                this.isAllDayEvent = true;
                if (endTime) {
                    this.endTime = format(
                        new Date(endTime),
                        "HH:mm"
                    );
                    this.isAllDayEvent = false;
                }

                this.region = region;
                this.location = location;
                this.price = price;
                this.available = available;
                this.full = full;
                this.images = images ?
                    images.map(
                        image => `${ apiUrl }/${ apiVersion }/event/image/${ image }`
                    ) :
                    [];
            }
        },
    }
}
</script>

<style lang="scss" scoped>
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.header {
    top: 0;
    z-index: 1;
}

.event-images {
    min-height: 200px;

    & > img {
        height: auto;
        width: 100%;
    }

    &:hover,
    &:focus {
        &:after {
            display: flex;
            justify-content: center;
            align-items: center;

            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;

            color: rgba(255, 255, 255, 1);
            background-color: rgba(0, 0, 0, 0.25);

            font-size: 2rem;
            font-weight: bolder;

            cursor: pointer;
        }
    }
}

.event-images {
    &:hover,
    &:focus {
        &:after {
            content: "Alter event images";
        }
    }
}

.thumbnails {
    .thumbnail-wrapper {
        height: 90px;
        width: 25%;

        @include media-breakpoint-up(lg) {
            width: 33.33%;
        }

        @include media-breakpoint-up(xl) {
            width: 25%;
        }

        & > .img-thumbnail {
            object-fit: contain;
        }
    }
}

.buttons {
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(3px);
    z-index: 3
}
</style>
